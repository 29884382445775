import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarCSA() {

  const onClickButton_CSAHome = () => {
    navigate ('/cloud-security-architect')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" alignContent="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
      <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
      <CardHeader gap="small">
        <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/6XzHR7K1HrmACwGDEXK8QN/6ecc5c2f538affc6f0f283ff248aa2d4/pe-persona-architect-avatar.jpg" style={{borderRadius: '50%'}} onClick={onClickButton_CSAHome} /></Box>
        <Heading level={4} style={{margin: '10px 10px 0 0'}}>Cloud Security Architect</Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Paragraph style={{fontSize: '14px', lineHeight: '1.4', marginBottom: '20px'}}>Cloud Security Architects design and build security patterns for cloud-based computing and data storage systems. They work with Infrastructure, Network Security, and Application teams to assess current and new systems to implement security solutions.</Paragraph>
          <Heading level={5} style={{marginBottom: '10px'}}>Challenges</Heading>
          <ul style={{fontSize: '14px', lineHeight: '1.5', marginBottom: '20px', padding: '0 0 0 15px'}}>
            <li>Staying abreast of current and emerging cyber threats and vulnerabilities</li>
            <li>Keeping current on new and changing data security regulations</li>
            <li>Understanding how changes to internal tools can impact security and vulnerability</li>
          </ul>
        </CardBody>
      </Card>
      <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
    </Box>
  );
}